import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Accordion",
  type: "rh/components/content/accordion",
  isContainer: false,
  config: {
    emptyLabel: "No topics defined.",
    isEmpty: (props: PropTypes) =>
      Object.values(props.topics || {}).length === 0
  }
};
