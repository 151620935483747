import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import React from "react";

export default AEMWrapper(
  {
    type: "rh/components/content/htmlheadtags",
    config: {
      emptyLabel: "HTMLHeadTags",
      isEmpty: _ => false
    }
  },
  loadable(
    () => import(/* webpackChunkName: "component-html-head-tags" */ "."),
    {
      fallback: <></>
    }
  )
);
