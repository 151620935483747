import { loadableReady } from "@loadable/component";
import analyticsLoader from "analytics/loader";
import "custom-elements";
import React, { ComponentType } from "react";
import ReactDOM from "react-dom";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import { removeQueryParams } from "utils/removeQueryParams";
import * as serviceWorker from "./serviceWorker";
import smoothscroll from "smoothscroll-polyfill";
import client from "graphql-client";
import yn from "yn";

import getIsReactRoute from "utils/getIsReactRoute";
import { processEnvServer } from "hooks/useSsrHooks";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";

const {
  CONFIG_GIT_SHA,
  ENVIRONMENT_CONFIG_POLLING_INTERVAL_SECONDS,
  FEATURE_AEM_SSR,
  FEATURE_APP_REFRESH_POLLING,
  FEATURE_SERVICE_WORKER,
  FEATURE_SSR
} = getGlobal("_env");

// check this
// const isSSRToggled = isSSRToggledWithClientRender();
// const isAEMSSRToggled = isAEMSSRToggledWithClientRender();

const isAEMSSRToggled =
  document.cookie.includes("FEATURE_AEM_SSR=true") || yn(FEATURE_AEM_SSR);

const isSSRToggled =
  document.cookie.includes("FEATURE_SSR=true") || yn(FEATURE_SSR);

smoothscroll.polyfill();

const w = window as any;

let isLocalStorageUpdate = false;

//certain pages break with SSR JSS style manipulation, we can use this to isolate them and remove the styles instead of move them (most pages need the opposite, so it is the default)
const forceRemovePaths = ["/content/rh-interiors/us/en/interiors"];

/* this works some times , not always */
if (isSSRToggled || isAEMSSRToggled) {
  const config = { attributes: true, childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    let alreadySetOnPosition = false;
    // Use traditional 'for loops' for IE 11
    for (const mutation of mutationsList) {
      if (mutation?.type === "childList") {
        if (mutation?.addedNodes?.length) {
          const isFirst = Array.from(mutation?.addedNodes).some(
            (node: HTMLElement) => {
              return node?.dataset?.meta === "makeStyles";
            }
          );

          if (isFirst && !alreadySetOnPosition) {
            const [style] = mutation?.addedNodes;
            const jss = document.getElementById("jss-server-side");
            if (!jss) return;

            if (isAEMSSRToggled && !getIsReactRoute()) {
              jss.remove();
            } else {
              style.before(jss);
            }

            alreadySetOnPosition = true;

            observer.disconnect();
          }
        }
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(document.head, config);
}

const renderApp = (App: ComponentType<any>) => {
  const renderReact = () => {
    ReactDOM[
      (isSSRToggled || isAEMSSRToggled) &&
      (window as any).__SSR__ &&
      !processEnvServer &&
      !isSSRToggledWithClientRender
        ? "hydrate"
        : "render"
    ](
      <App client={client} isLocalStorageUpdate={isLocalStorageUpdate} />,
      document.getElementById("spa-root")
    );
  };
  loadableReady(() => {
    renderReact();
  });
};

export const appFactory = (App: ComponentType<any>) => ({
  initialize: () => {
    try {
      renderApp(App);
    } catch (err) {
      console.log(err);
    }
    analyticsLoader(a => a.listenAnalyticsEvents(document.body));
    removeQueryParams(["checkCookiesEnabled"]);

    // Learn more about service workers: https://bit.ly/CRA-PWA
    if (yn(FEATURE_SERVICE_WORKER)) {
      serviceWorker.register();
    }

    const environmentConfigPollingInternvalSeconds = Number(
      ENVIRONMENT_CONFIG_POLLING_INTERVAL_SECONDS || 60
    );
    if (yn(FEATURE_APP_REFRESH_POLLING) && !processEnvServer) {
      localStorage.removeItem("appRefreshRequired");
      isLocalStorageUpdate = false;
      setInterval(() => {
        console.warn("set-interval called");
        fetch("/env-config.js")
          .then(async response => {
            const data = await response.text();
            var currentSha = data.match(/CONFIG_GIT_SHA: "(.*)"/)
              ? data.match(/CONFIG_GIT_SHA: "(.*)"/)[1]
              : data.match(/"CONFIG_GIT_SHA": "(.*)"/)
              ? data.match(/"CONFIG_GIT_SHA": "(.*)"/)[1]
              : "";
            console.warn(
              "current SHA, CONGIG_GIT_SHA: ",
              currentSha,
              CONFIG_GIT_SHA
            );
            if (
              !!currentSha &&
              !!CONFIG_GIT_SHA &&
              currentSha !== CONFIG_GIT_SHA
            ) {
              console.warn(
                `Config SHA mismatch, setting "appRefreshRequired" to "yes"`
              );
              localStorage.setItem("appRefreshRequired", "yes");
              isLocalStorageUpdate = true;
              renderApp(App);
            }
          })
          .catch(err => {
            console.warn("Unable to fetch env-config.js", err);
          });
      }, environmentConfigPollingInternvalSeconds * 1000);
    }
  }
});
