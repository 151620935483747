import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import React from "react";

export default AEMWrapper(
  {
    type: "rh/components/content/slide",
    config: {
      emptyLabel: "Slide component",

      isEmpty: function (props: any) {
        return !props || !props.backgroundType;
      }
    }
  },
  loadable(() => import(/* webpackChunkName: "component-rh-aem-slide" */ "."), {
    fallback: <></>
  })
);
