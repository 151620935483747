import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import meta from "./aem-meta";
import React from "react";

const ComponentButton = loadable(
  () => import(/* webpackChunkName: "component-button" */ "."),
  { fallback: <></> }
);

export default AEMWrapper(meta, ComponentButton);
