import React from "react";

/**
 * Inspired by this: https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c
 */
type Props = {
  children: React.ReactNode;
};

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void;
type ErrorHandlingComponent<Props> = (
  props: Props,
  error?: Error
) => React.ReactNode;

type ErrorState = { error?: Error };

export default function Catch<Props extends {}>(
  component: ErrorHandlingComponent<Props>,
  errorHandler?: ErrorHandler
): React.ComponentType<Props> {
  function Inner(p) {
    const props = p.props;
    const error = p.error;
    return <>{component(props, error)}</>;
  }
  return class extends React.Component<Props, ErrorState> {
    state: ErrorState = {
      error: undefined
    };
    static getDerivedStateFromError(error: Error) {
      return { error };
    }
    componentDidCatch(error: Error, info: React.ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info);
      }
    }
    render() {
      return <Inner error={this.state.error} props={this.props} />;
    }
  };
}

export const ErrorBoundary = Catch(function ErrorBoundary(
  props: Props,
  error?: Error
) {
  const children = props?.children;
  if (error) {
    return (
      <div className="error-boundary">
        <h2>An error has occurred</h2>
        <h4>{error.message}</h4>
      </div>
    );
  } else {
    return <>{children}</>;
  }
});
