import { PropTypes } from "aem/ComponentWrapper";
import React from "react";

export default {
  title: "Chat Trigger",
  type: "rh/components/content/chat-trigger",
  isContainer: true,
  config: {
    emptyLabel: "Chat Trigger",
    isEmpty: ({ children }: PropTypes) => React.Children.count(children) === 0
  }
};
