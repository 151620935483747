import { useEnv } from "hooks/useEnv";
import React, { FC, useEffect } from "react";
import { RHR_DRAWER_TRANSITION_DURATION } from "utils/constants";
import { Drawer as MuiDrawer, DrawerProps } from "utils/material-ui-core";
import { enable, disable } from "utils/scrollLock";
import memoize from "utils/memoize";
import useDidMountEffect from "hooks/useDidMountEffect";
import useIsIOSDevice from "hooks/useIsIOS";

interface NewPDPProps {
  isNewPdpLayout?: boolean;
  stopBackgroundScrolling?: boolean;
}

type CombinedProps = DrawerProps & NewPDPProps;
const toggleScroll = (scroll: boolean, isNewPdpLayout?: boolean): void => {
  if (!isNewPdpLayout) {
    document.documentElement.style.overflow = scroll ? "visible" : "hidden";
  }
};

const Drawer: FC<CombinedProps> = props => {
  const { open, isNewPdpLayout, stopBackgroundScrolling } = props;
  const env = useEnv();
  const isIOSDevice = useIsIOSDevice();

  useEffect(() => {
    if (!stopBackgroundScrolling) {
      toggleScroll(!open, isNewPdpLayout);

      return () => toggleScroll(true, isNewPdpLayout);
    }
  }, [open, isNewPdpLayout]);

  useDidMountEffect(() => {
    if (!isIOSDevice) return;

    if (open) {
      enable?.();
    }

    return () => disable();
  }, [open]);

  return (
    <MuiDrawer
      id={props?.id || "component-drawer"}
      transitionDuration={RHR_DRAWER_TRANSITION_DURATION}
      {...props}
    />
  );
};

export default memoize(Drawer);
