import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Drop Down Flyout Menu",
  type: "rh/components/content/dropdownflyoutmenu",
  isContainer: false,
  config: {
    emptyLabel: "Drop Down Flyout Menu - No items configured",
    isEmpty: ({ groups }: PropTypes) =>
      Object.values(groups?.[0]?.items ?? {}).length === 0
  }
};
